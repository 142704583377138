.filter {
  margin-right: $gutter;
  margin-bottom: $gutter-small;
  text-decoration: none;
  display: flex;

  &.filter-is-active {
    font-weight: bold;
  }
}

.filter__input {
  @include hide;
}

.filter__label {
  display: flex;
  cursor: pointer;
}

.filter__circle {
  top: 0.1em;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: rem(17);
  height: rem(17);
  border: $border-primary;
  border-radius: 50%;
  margin-right: $gutter-small;
  flex-shrink: 0;

  &::before {
    display: block;
    content: '';
    width: rem(11);
    height: rem(11);
    border-radius: 50%;
    background: $green;
    position: absolute;
    opacity: 0;
    transition: opacity 0.1s;
  }
}

.filter__input:checked + .filter__label {
  font-weight: bold;

  .filter__circle::before {
    opacity: 1;
  }
}

.filter-is-active {
  .filter__circle::before {
    opacity: 1;
  }
}


