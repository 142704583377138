.reveal {
  position: relative;

  &__container {
    overflow: hidden;
  }

  [data-reveal] {
    display: block;
  }

  [data-reveal=slide-down].hidden {
    transform: translateY(-101%);
  }

  &__arrow {
    position: absolute;

    &--top {
      top: $gutter-large;
      right: $gutter-large;
    }

    &--bottom {
      bottom: $gutter-large;
      left: $gutter-large;
    }
  }
}
