.featured__container {
  @include wrapper($gutter-extra-large);
}

.featured__header {
  width: 100%;
  margin-bottom: $gutter-large;

  @include media($m-screen-up) {
    width: 45%;
  }

  h2 {
    @extend h4;
    margin-bottom: $gutter;
  }
}
