.filters {
  @include listReset;
  margin-top: 0;

  @include media($xl-screen-down) {
    display: flex;
    flex-wrap: wrap;
    // Compensate for filter margin gutter
    margin-bottom: -$gutter-small;
  }

  &--horizontal {
    display: flex;
    flex-wrap: wrap;
    // Compensate for filter margin gutter
    margin-bottom: -$gutter-small;
  }
}
