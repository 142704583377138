// Typography
$font-heading: 'Judge', sans-serif;
$font-body: 'Grotesc', sans-serif;

// Colors
$black: #2B2927;
$dark-green: #003330;
$green: #00CF6B;
$light-green: #C2F9BA;
$grey: #DADCD9;
$white: #FFFFFF;

// Spacing
$gutter-extra-small: rem(5);
$gutter-small: rem(10);
$gutter: rem(20);
$gutter-medium: rem(25);
$gutter-large: rem(40);
$gutter-extra-large: rem(80);

// Sizes
$bar: rem(60);
$max-width: rem(1400);

// Borders
$border-width: 1px;
$border-primary: $border-width solid $green;
$border-secondary: $border-width solid $dark-green;
$border-white: $border-width solid $white;

// Easing
$easing-quintic: cubic-bezier(0.23, 1, 0.32, 1);
