.people-links {
  padding-bottom: $gutter;

  @include media($xl-screen-down) {
    display: flex;
    @include flowH;
  }

  @include media($xl-screen-up) {
    @include flowV($gutter-small);
  }
}
