.circle-number {
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-variant-numeric: tabular-nums;
  font-weight: bold;
  font-size: rem(16);
  position: relative;
  color: $white;
  padding: 0.4em;

  &::before {
    display: block;
    content: '';
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background: $black;
    position: absolute;
    z-index: 0;
  }
}

.circle-number__text {
  margin-top: -0.07em;
  z-index: 1;
}