.rows {
  @include flowV($gutter-small);
}

.rows__row {
  display: flex;
  align-items: baseline;
  font-size: rem(16);

  + #{&} {
    padding-top: $gutter-small;
    border-top: $border-primary;
  }
}

.row__year {
  margin-right: $gutter;

  @include media($xl-screen-up) {
    margin-right: $gutter-extra-large;
  }
}