.section-slider {
  position: relative;
  width: 100%;
  height: rem(200);
  // Approximate aspect ratio. Glide.js calculates dimensions incorrectly unless some sort of height is set on the container element

  @include media($xs-screen-up) {
    height: rem(250);
  }

  @include media($s-screen-up) {
    height: rem(400);
  }

  @include media($m-screen-up) {
    height: rem(350);
  }

  @include media($l-screen-up) {
    height: rem(400);
  }

  .glide {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .glide__track,
  .glide__slides,
  .glide__slide {
    height: 100%;
  }

  .glide__slides {
    will-change: auto;
  }

  .glide__slide-placeholder {
    height: 100%;
    background: $grey;
  }

  .glide__image {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .glide__video {
    width: 100%;
    height: 100%;
    position: relative;

    .video {
      position: relative;
      height: 100%;
      overflow: hidden;
    }

    iframe {
      width: 177.77777778%;
      height: 56.25%;
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .glide__arrows {
    display: flex;
    padding-right: $gutter-small;
  }

  .glide__arrow {
    @include btnReset;
    display: flex;

    &:first-child {
      padding-right: $gutter-small;
    }
  }
}

.section-slider__controls {
  padding-top: $gutter-small;
  display: flex;
  flex-shrink: 0;
}

.section-slider__buttons {
  padding-right: $gutter-small;
  display: flex;
}

.section-slider__button:first-child {
  margin-right: $gutter-small;
}

.section-slider__numbers {
  line-height: 1;
  display: flex;
  // So numbers don't move when changing index
  font-variant-numeric: tabular-nums;
}
