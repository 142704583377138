.practice {
  @include wrapper($gutter-extra-large);
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  @include media($s-screen-up) {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  @include media($l-screen-up) {
    padding-bottom: 0;
    padding-right: 0;
  }
}

.practice__subnav {
  padding-left: $gutter;
  padding-right: $gutter;

  @include media($m-screen-up) {
    padding-left: $gutter-large;
    padding-right: $gutter-large;    
  }
}
