.subnav {
  font-size: rem(16);

  @include media($s-screen-up) {
    font-size: rem(18);
  }

  .filters {
    padding-bottom: $gutter;
  }
}

.subnav__title {
  font-family: $font-body;
  text-transform: none;
  font-size: inherit;
  padding-bottom: $gutter;
  width: 100%;

  @include media($xs-screen-up) {
    width: auto;
    padding-bottom: 0;
    margin-right: $gutter-large;
  }
}

.subnav__row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media($l-screen-down) {
    @include flowV;
    flex-wrap: wrap;
  }
}

.subnav__items {
  display: flex;
  align-items: baseline;
  width: 100%;
  flex-wrap: wrap;

  &--compensate {
    // Compensate for subnav__item margin gutter
    margin-bottom: -$gutter-small;
  }

  &--utils {
    justify-content: space-between;

    @include media($l-screen-up) {
      justify-content: flex-end;
    }
  }
}

.subnav__item {
  margin-right: $gutter;
  margin-bottom: $gutter-small;

  @include media($xs-screen-up) {
    margin-right: $gutter-large;
  }
}

.subnav__menu {
  height: 0;
  overflow: hidden;

  &.submenu-is-active {
    height: auto;
  }
}

.subnav__buttons {
  display: flex;
  @include flowH($gutter-medium);
  @include media($xs-screen-up) {
    @include flowH($gutter-large);
  }
}

.subnav__link {
  display: flex;
  align-items: baseline;
  font-size: rem(16);
  text-decoration: none;

  @include media($s-screen-up) {
    font-size: rem(18);
  }

  &.subnav-link-is-active {
    font-weight: bold;
  }
}

.subnav__reset {
  align-self: center;
  order: 2;

  @include media($l-screen-up) {
    order: 1;
  }

  &.hidden {
    visibility: hidden;
  }

  .icon {
    margin-right: $gutter-small;

    @include media($l-screen-up) {
      margin-right: $gutter-small;
    }
  }
}

.subnav__switch {
  order: 1;
  margin-left: 0;

  @include media($l-screen-up) {
    order: 2;
    margin-left: $gutter-medium;
  }
}

.subnav__button {
  @include btnReset;
  align-items: center;
  text-decoration: none;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: rem(6);
    height: rem(6);
    background: $green;
    border-radius: 50%;
    position: absolute;
    left: rem(-12);
    margin-bottom: rem(-3);
    opacity: 0;
    transition: opacity 0.1s;

    @include media($l-screen-up) {
      width: rem(8);
      height: rem(8);
      left: rem(-16);
    }
  }

  &.filter-is-active::before {
    opacity: 1;
  }
}

.subnav__arrow {
  text-align: left;
  width: 0;
  overflow: hidden;
  transition: width 0.1s $easing-quintic;

  .subnav-link-is-active & {
    width: rem(33);
  }
}

.subnav__arrow--down {
  width: auto;
  margin-left: $gutter-extra-small;
  transition: transform 0.1s;
  transform-origin: center;
  font-size: 0;
  display: block;

  @include media($s-screen-up) {
    margin-left: $gutter-small;
  }

  .subnav__button.submenu-is-active & {
    transform: rotateX(180deg);
  }
}
