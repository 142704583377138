.breakout {
  &--light-green {
    background: $light-green;
  }

  &--green {
    background: $green;
    color: $dark-green;
  }
}

.breakout__inner {
  @include wrapper($gutter-extra-large);

  &--extra-padding {
    padding-top: $gutter-extra-large;
    padding-bottom: $gutter-extra-large;
  }
}

.breakout__text {
  margin-bottom: $gutter;

  h2,
  p {
    margin-bottom: $gutter;
  }
}

.breakout__link {
  display: flex;
  gap: 1rem;
  align-items: baseline;
  justify-content: space-between;
}

.breakout--default {
  .breakout__title {
    width: 70%;
    font-size: rem(30);
    line-height: 0.9;
    margin-bottom: $gutter;

    @include media($l-screen-up) {
      font-size: rem(70);
    }

    @include media($xl-screen-up) {
      width: 50%;
      font-size: rem(90);
      margin-bottom: $gutter-large;
    }
  }

  .breakout__body {
    position: relative;
    width: 70%;
    left: 30%;
    font-size: rem(18);

    @include media($l-screen-up) {
      width: 50%;
      left: 50%;
      font-size: rem(30);
    }
  }
}

.breakout--link {
  .breakout__text {
    max-width: rem(600);

    p {
      @include media($m-screen-up) {
        font-size: rem(24);
        margin-bottom: $gutter-extra-large;
      }
    }
  }
}

.breakout--link-with-image {
  .breakout__inner {
    @include media($xl-screen-up) {
      @include grid(12);
      @include colGap();
      padding-top: $gutter-extra-large;
      padding-bottom: $gutter-extra-large;
    }
  }

  .breakout__text {
    @include media($xl-screen-up) {
      @include col(4);
    }
  }

  .breakout__image {
    position: relative;

    img {
      width: 100%;
    }

    @include media($xl-screen-up) {
      @include col(8);
      width: calc(100% + $gutter-large);
    }
  }
}
