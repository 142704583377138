.clients {
  @include wrapper($gutter-extra-large);
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  @include media($s-screen-up) {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  @include media($l-screen-up) {
    padding-bottom: 0;
    padding-right: 0;

    .filters {
      margin-top: 0;
    }
  }

  .cols {
    padding-top: 0;
  }

  .divider {
    margin-top: 0;
  }
}

.clients__list {
  @include listReset;
  @include grid(2);
  @include rowGap;
  margin-top: $gutter-large;

  @include media($m-screen-up) {
    @include colGap;
  }
}

.clients__item {
  @include col(2);
  @include media($m-screen-up) {
    @include col(1);
  }
}
