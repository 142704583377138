.video {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: math.div(16, 9);

  iframe {
    width: 100%;
    height: 100%;
  }
}
