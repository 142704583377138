.back {
  display: flex;
  align-items: baseline;
  font-size: rem(16);
  font-weight: bold;
  text-decoration: none;

  @include media($s-screen-up){
    font-size: rem(18);
  }
}

.back__arrow {
  margin-left: $gutter-small;
}
