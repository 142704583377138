.loading-overlay {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 1;
  width: $gutter-medium;

  &.hidden {
    display: none;
  }

  &__spinner {
    display: inline-block;
    width: $gutter-medium;
  }

  svg {
    animation: rotator 1s linear infinite;

    circle {
      stroke-dasharray: 140;
      stroke-dashoffset: 0;
      transform-origin: center;
      stroke: $green;
    }
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
