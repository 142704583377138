.project {
  @include wrapper($gutter-extra-large);
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  @include media($s-screen-up) {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  @include media($l-screen-up) {
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;
  }
}

.project__row {
  @include wrapper($gutter-extra-large);
  padding-top: 0;
  padding-bottom: $gutter-small;
  padding-left: $gutter;

  @include media($s-screen-up) {
    padding-top: 0;
    padding-bottom: $gutter;
    padding-left: $gutter;
    padding-right: $gutter;
  }

  @include media($m-screen-up) {
    padding-top: 0;
    padding-bottom: $gutter;
    padding-left: $gutter-large;
    padding-right: $gutter-large;
  }

  @include media($l-screen-up) {
    padding-top: 0;
    padding-bottom: $gutter-medium;
    padding-left: $gutter-large;
  }
}

.project__subtitle {
  font-family: $font-body;
  text-transform: none;
  font-size: rem(16);

  @include media($s-screen-up) {
    font-size: rem(18);
  }
}

