@font-face {
  font-family: 'Judge';
  src:
    url('../fonts/F37Judge-Bold.woff2') format('woff2'),
    url('../fonts/F37Judge-Bold.woff') format('woff');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Grotesc';
  src:
    url('../fonts/F37Grotesc-Book.woff2') format('woff2'),
    url('../fonts/F37Grotesc-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Grotesc';
  src:
    url('../fonts/F37Grotesc-BookItalic.woff2') format('woff2'),
    url('../fonts/F37Grotesc-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Grotesc';
  src:
    url('../fonts/F37Grotesc-Bold.woff2') format('woff2'),
    url('../fonts/F37Grotesc-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Grotesc';
  src:
    url('../fonts/F37Grotesc-BoldItalic.woff2') format('woff2'),
    url('../fonts/F37Grotesc-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
