.card {
  display: block;
  position: relative;
  overflow: hidden;
  padding: $gutter;
  aspect-ratio: 4 / 3;

  @include media($xl-screen-down) {
    .icon--arrow-small {
      display: inline-block;
    }

    .icon--arrow-medium {
      display: none;
    }
  }

  @include media($xl-screen-up) {
    .icon--arrow-small {
      display: none;
    }

    .icon--arrow-medium {
      display: inline-block;
    }
  }
}

.card--image {
  background: $light-green;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  iframe {
    width: 177.77777778%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.card--simple-link {
  background: $dark-green;

  .card__title {
    color: $green;
  }
}

.card--text-link {
  background: $green;
  color: $dark-green;

  &.card__label {
    color: $dark-green;
  }
}

.card--text {
  background: $green;
  color: $dark-green;
}

.card--explore {
  @include col(2);
  @include media($s-screen-up) {
    @include col(1);
  }
}

.card--simple-link,
.card--text-link {
  text-decoration: none;

  .icon {
    position: absolute;
    bottom: $gutter;
    right: $gutter;

    &.icon--white svg line,
    &.icon--white svg polyline {
      transition: stroke 0.33s;
    }
  }

  &:hover {
    .icon--white svg line,
    .icon--white svg polyline {
      stroke: $black;
      transition-delay: 0.1s;
    }
  }
}

.card--featured {
  margin-bottom: $gutter;
  height: 0;
  aspect-ratio: auto;
  // padding-bottom: 60%;

  // @include media($l-screen-up) {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(5),
    &:nth-child(6) {
      padding-bottom: 80%;
    }

    &:nth-child(3) {
      padding-bottom: 60%;
    }

    &:nth-child(4) {
      padding-bottom: 100%;
    }
  // }
}

.card__details {
  position: relative;
}

.card__title,
.card__text {
  position: relative;
  @extend h3;
}

.card__date {
  position: relative;
  color: $white;

  .card:hover & {
    color: $black;
  }
}

.card__label {
  position: absolute;
  font-weight: bold;
  bottom: $gutter;
  left: $gutter;
  padding-right: $gutter-extra-large;
  color: $white;
  transition: color 0.33s 0.1s;

  .card:hover & {
    color: $black;
  }
}

.card__overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: $gutter;
  background: $light-green;
  transform: translate3d(-100%, 100%, 0);
  transition: transform 0.66s $easing-quintic;

  .card:hover & {
    transform: translate3d(0, 0, 0);
  }
}

.card__overlay-label,
.card__overlay-title {
  display: block;
}

.card__overlay-title {
  @extend h5;
  margin-top: $gutter-small;
}

.card__overlay-arrow {
  position: absolute;
  right: $gutter;
  bottom: $gutter;
}
