.container {
  margin: 0;
  background: $white;

  @include media($m-screen-up) {
    margin: 0 $bar;
  }

  &--left,
  &--right {
    @include media($m-screen-up) {
      width: calc(100% - calc(#{$bar} * 2));
    }
  }

  &--left {
    left: 0;

    @include media($m-screen-up) {
      margin: 0 calc(#{$bar} * 2) 0 0;
    }
  }

  &--right {
    @include media($m-screen-up) {
      margin: 0 0 0 calc(#{$bar} * 2);
    }
  }
}
