.switch {
  text-decoration: none;
  display: flex;
  align-items: baseline;
  user-select: none;
}

.switch__switch {
  display: flex;
  align-items: center;
  width: rem(36);
  height: rem(17);
  border: $border-primary;
  border-radius: rem(9);
  padding: 0 rem(3);
  margin: 0 $gutter-small;

  &::before {
    content: '';
    display: block;
    width: rem(11);
    height: rem(11);
    border-radius: 50%;
    background: $green;
  }
}

.list-view-is-active .switch__switch::before {
  transform: translateX(rem(17));
}
