.people {
 	@include grid;
 	@include rowGap;
 	@include media($s-screen-up) {
 		@include colGap;
 	}
}

.people__item {
	@include col;
	@include media($s-screen-up) {
		@include col(6);
	}

	@include media($xl-screen-up) {
		@include col(4);
	}
}