p {
  margin: 0;
  line-height: 1.25;
}

strong {
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-heading;
  text-transform: uppercase;
  margin: 0;
  line-height: 1;
}

h1 {
  font-size: rem(36);
  line-height: 0.9;

  @include media($m-screen-up) {
    font-size: rem(75);
  }
}

h2 {
  font-size: rem(32);
  line-height: 0.9;

  @include media($m-screen-up) {
    font-size: rem(60);
  }
}

h3 {
  font-size: rem(28);

  @include media($m-screen-up) {
    font-size: rem(50);
  }
}

h4 {
  font-size: rem(24);

  @include media($m-screen-up) {
    font-size: rem(40);
  }
}

h5 {
  font-size: rem(20);

  @include media($m-screen-up) {
    font-size: rem(30);
  }
}

h6 {
  font-size: rem(18);

  @include media($m-screen-up) {
    font-size: rem(25);
  }
}
