.link {
  text-decoration: none;
  font-weight: bold;

  &--icon {
    vertical-align: middle;

    .link__text {
      margin-right: $gutter-small;
    }
  }

  &--secondary {
    font-weight: normal;
  }

  &--special {
    font-family: $font-heading;
    text-transform: uppercase;
    font-size: rem(24);

    .icon {
      vertical-align: baseline;
    }
  }

  &--s {
    font-size: rem(24);
  }

  &--xl {
    @extend h2;
  }

  &:hover,
  &:active {
    text-decoration: underline;
  }
}
