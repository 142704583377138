.profile {
  @include flowV($gutter-large);
}

.profile__grid {
	@include grid(2);
	@include rowGap;
  @include media($l-screen-up) {
    @include colGap($gutter-large);
  }
}

.profile__left {
	@include flowV($gutter-large);
  @include col(2);
  @include media($l-screen-up) {
    @include col(1);
  }
}

.profile__right {
  @include col(2);
  order: -1;
  @include media($l-screen-up) {
    @include col(1);
    order: 0;
  }
}

.profile__group {
	@include flowV($gutter-extra-small);
}

.profile__name,
.profile__role,
.profile__projects-title {
	font-family: $font-body;
	text-transform: none;
	font-size: rem(24);
}

.profile__location,
.profile__qualifications {
	font-family: $font-body;
	text-transform: none;
	font-size: rem(16);
}

.profile__role,
.profile__location {
	font-weight: normal;
}

.profile__bio {
	@include flowV;
  font-size: rem(16);
}

.profile__image-container {
  position: relative;
  width: 100%;
}

.profile__image {
  margin-bottom: $gutter;
}

.profile__projects {
  @include listReset;
  @include flowV;
}

.profile__projects-title {
  margin-bottom: $gutter;
}

.profile__project {
  text-decoration: none;
  display: flex;
  border-top: $border-primary;
  padding-top: $gutter;
  @include flowH;
}

.profile__year,
.profile__title {
  font-family: $font-body;
	text-transform: none;
  font-size: rem(18);
}

.profile__title {
  font-weight: normal;
}
