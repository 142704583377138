.quote {
  position: relative;
  background: $light-green;

  // &::before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   background: $light-green;
  //   height: 100%;
  //   width: calc(100% + #{$gutter-large});
  //   left: -$gutter;

  //   @include media($m-screen-up) {
  //     width: calc(100% + #{$gutter-extra-large});
  //     left: -$gutter-large;
  //   }

  //   @include media($l-screen-up) {
  //     width: calc(100% + #{$gutter-large} + #{$gutter-medium});
  //     left: -$gutter-medium;
  //   }
  // }

  // .project & {
  //   &::before {
  //     width: calc(100% + #{$gutter-large});
  //     left: -$gutter;

  //     @include media($m-screen-up) {
  //       width: calc(100% + #{$gutter-extra-large});
  //       left: -$gutter-large;
  //     }

  //     @include media($l-screen-up) {
  //       width: calc(100% + #{$gutter-large} + #{$gutter-large});
  //       left: -$gutter-large;
  //     }
  //   }
  // }
}

.quote__blockquote {
  position: relative;
  margin: 0;
  padding: $gutter;
  @include flowV;

  @include media($l-screen-up) {
    padding: $gutter-large;
  }
}

.quote__text {
  max-width: rem(700);
  @extend h3;
}

.quote__source {
  font-size: rem(16);
}

