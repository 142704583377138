.projects {
  @include wrapper($gutter-extra-large);
}

.projects__subnav {
  padding-bottom: $gutter;
}

.projects__grid {
  @include grid;
  @include rowGap;

  @include media($s-screen-up) {
    @include colGap;
  }
}
