.cols {
	display: flex;
  flex-direction: column;

  @include media($xl-screen-up) {
    padding-top: $gutter;
    flex-direction: row;
  }
}

.cols__left {
  @include flowV($gutter);
  width: 100%;
	padding-bottom: $gutter;
	padding-left: $gutter;
	padding-right: $gutter;
	align-self: flex-start;
  font-size: rem(16);

  &--hide-mobile {
    @include media($xl-screen-down) {
      display: none;
    }
  }

  @include media($m-screen-up) {
    padding-left: $gutter-large;
    padding-right: $gutter-large;
  }

	@include media($xl-screen-up) {
		display: block;
		width: rem(250);
		flex-shrink: 0;
    font-size: rem(18);
    position: sticky;
    top: $gutter-large;
	}
}

.cols__right {
	flex-grow: 1;
  padding-top: $gutter;

	@include media($xl-screen-up) {
		border-left: $border-primary;
    padding-top: 0;
	}
}
