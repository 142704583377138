.grid {
  @include media($l-screen-up) {
    columns: 2;
    column-gap: $gutter;
  }

  @include media($xl-screen-up) {
    columns: 3;
  }
}
