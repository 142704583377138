.top-link {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  width: $gutter-large;
  height: $gutter-large;
  background: $green;
  border-radius: calc($gutter-large / 2);
  bottom: calc($gutter + $bar);
  right: $gutter;
  opacity: 0;
  transition: opacity 0.3s $easing-quintic;
  pointer-events: none;
  z-index: 100;

  .scrolling-up & {
    opacity: 1;
    pointer-events: auto;
  }

  .icon {
    position: relative;
    top: rem(2);
  }

  @include media($m-screen-up) {
    bottom: $gutter-large;

    .container--center & {
      right: calc($bar + $gutter-large);
    }

    .container--right & {
      right: $gutter-large;
    }

    .container--left & {
      right: calc(calc($bar * 2) + $gutter-large);
    }
  }
}
