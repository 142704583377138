.blockquote {
	margin: 0;
	@include flowV;
}

.blockquote__text {
	font-family: $font-heading;
  text-transform: uppercase;
	font-size: rem(20);

  @include media($m-screen-up) {
    font-size: rem(30);
  }
}