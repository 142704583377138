.form {
  $field-height: rem(44);
  $border-width: 1px;

  label,
  input[type=text],
  input[type=email] {
    font-family: $font-body;
    font-size: rem(18);
    height: $field-height;
    border: $border-width solid $green;
    padding: $gutter-small;
    transition: border 0.2s $easing-quintic;
  }

  label {
    background: $green;
    padding: $gutter-small $gutter;
  }

  input[type=text],
  input[type=email] {
    flex-grow: 1;
    border-radius: 0;

    &:hover,
    &:focus {
      outline: none;
      border-color: $dark-green;
    }
  }

  button,
  input[type=submit] {
    @include btnReset;
    cursor: pointer;
  }

  &__input,
  &__submit {
    position: relative;
    display: flex;
  }

  &__input {
    margin-right: $gutter-small;
    max-width: rem(400);
  }

  &__submit {
    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: calc($field-height - rem(4));
      height: calc($field-height - rem(4));
      right: rem(2);
      top: rem(2);

      svg {
        position: relative;
        top: rem(2);
      }

      &:hover {
        background: $light-green;
      }
    }
  }
}
