.newsletter {
  border-top: $border-primary;

  &__inner {
    @include wrapper($gutter-extra-large);
    padding-top: $gutter-large;
    padding-bottom: $gutter-large;
  }

  &__title {
    margin-bottom: $gutter-small;
  }

  &__description {
    margin-bottom: $gutter;
  }
}
