.related {
  @include grid;
  @include rowGap;
  @include media($s-screen-up) {
    @include colGap;
  }

  .card {
    aspect-ratio: 4 / 3;
    @include col;
    @include media($s-screen-up) {
      @include col(6);
    }
  }
  
  &--3 {
    .card {
      @include media($s-screen-up) {
        @include col(4);
      } 
    }
  }
}