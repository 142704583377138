.divider {
  border: none;
  height: 1px;
  background: $green;
  margin: $gutter 0;

  &--mobile {
    @include media($l-screen-up) {
      display: none;
    }
  }
}
