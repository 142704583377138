.explore__grid {
  @include grid(1);
  @include rowGap;
  @include colGap;

  @include media($s-screen-up) {
    @include grid(2);
  }

  @include media($m-screen-up) {
    @include grid(1);
  }

  @include media($l-screen-up) {
    @include grid(2);
  }
}
