.icon {
  display: inline-block;
  vertical-align: middle;

  svg {
    display: inline-block;
  }

  &--arrow-extra-small svg {
    width: rem(16);
    height: rem(16);
  }

  &--arrow-small svg {
    width: rem(18);
    height: rem(18);
  }

  &--arrow-small-left svg,
  &--arrow-small-right svg {
    width: rem(25);
    height: rem(18);
  }

  &--arrow-small-up svg,
  &--arrow-small-down svg {
    width: rem(18);
    height: rem(26);
  }

  &--arrow-medium svg {
    width: rem(36);
    height: rem(36);
  }

  &--arrow-large svg {
    width: rem(48);
    height: rem(48);
  }

  &--arrow-large-down svg {
    width: rem(48);
    height: rem(66);
  }

  &--search svg {
    width: rem(24);
    height: rem(24);
  }

  &--close svg {
    width: rem(16);
    height: rem(16);
  }

  &--white svg {
    line,
    polyline,
    circle,
    path {
      stroke: $white;
    }
  }

  &--green svg {
    line,
    polyline,
    circle,
    path {
      stroke: $green;
    }
  }

  &--flipped svg {
    transform: rotate(180deg);
  }
}

.icon--close {
  .subnav__button & {
    font-size: 0;
  }
}
