.carousel {
  width: 100%;
}

.carousel__container {
  width: 100%;
  height: rem(250);

  @include media($s-screen-up) {
    height: rem(300);
  }

  @include media($m-screen-up) {
    height: rem(400);
  }
}

.carousel__slide {
  max-width: 100%;
  width: auto;
  height: 100%;
  margin-right: $gutter;

  &--video {
    max-width: 100%;
    aspect-ratio: 16 / 9;
  }

  .video {
    width: 100%;
    height: 100%;
    aspect-ratio: initial;

    &::after {
      display: none;
    }
  }
}

img.carousel__image {
  display: block;
  height: 100%;
  width: auto;
}

.carousel__controls {
  padding-top: $gutter-small;
  display: flex;
  align-items: baseline;
}

.carousel__buttons {
  display: flex;
  margin-right: $gutter-small;
}

.carousel__button {
  @include btnReset;
  display: block;

  &--prev {
    margin-right: $gutter-small;
  }
}

.slider__numbers {
  line-height: 1;
  display: flex;
  // So numbers don't move when changing index
  font-variant-numeric: tabular-nums;
}
