.hero__featured {

  // Fix for issue with Glide flashing cloned slides before fully rendered
  .glide {
    .glide__slide--clone img {
      opacity: 0;
    }
  }

  .glide.is-running {
    .glide__slide--clone img {
      opacity: 1;
    }
  }

  .glide {
    position: relative;

    &__slides {
      transition-delay: 0.2s !important;
    }

    &__slide {
      position: relative;
    }

    &__arrow {
      @include btnReset;
      position: absolute;
      top: 50%;
      left: 0;

      &--left {
        margin-left: $gutter;

        @include media($m-screen-up) {
          margin-left: $gutter-large;
        }
      }

      &--right {
        margin-right: $gutter;
        left: auto;
        right: 0;

        @include media($m-screen-up) {
          margin-right: $gutter-large;
        }
      }
    }

    &__bullets {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      gap: rem(5);
      padding: $gutter;

      @include media($m-screen-up) {
        left: auto;
        right: 0;
        padding: $gutter-large;
      }
    }

    &__bullet {
      @include btnReset;
      position: relative;
      width: rem(12);
      height: rem(12);
      border-radius: 50%;
      border: $border-primary;
      border-color: $white;

      &::after {
        content: "";
        position: absolute;
        width: rem(6);
        height: rem(6);
        border-radius: 50%;
        top: rem(2);
        left: rem(2);
        background: none;
        transition: background 0.3s $easing-quintic;
      }

      &--active::after {
        background: $white;
      }
    }
  }
}
