.footer {
  background: $black;
  color: $white;

  &__inner {
    @include wrapper($gutter-extra-large);
    padding-top: $gutter-large;
    padding-bottom: $gutter-large;
  }

  &__locations {
    @include grid(1);
    @include rowGap();
    padding-bottom: $gutter;
    margin-bottom: $gutter;
    border-bottom: $border-white;

    @include media($m-screen-up) {
      @include grid(2);
      padding-bottom: $gutter-large;
      margin-bottom: $gutter-large;
    }

    @include media($xxl-screen-up) {
      @include grid(4);
    }
  }

  &__location {
    h5 {
      margin-bottom: $gutter-small;
    }
  }

  &__details {
    @include grid(1);
    @include rowGap();

    @include media($l-screen-up) {
      @include grid(2);
    }
  }

  &__info {
    order: 2;

    @include media($l-screen-up) {
      order: 1;
    }
  }

  &__logo {
    max-width: rem(240);
    margin-bottom: $gutter;
  }

  &__legal {
    font-size: rem(12);
  }

  &__links {
    @include grid(2);
    @include colGap();
    order: 1;

    @include media($l-screen-up) {
      order: 2;
      @include grid(3);
    }
  }

  &__link-list {
    &:nth-child(1) {
      order: 2;

      @include media($l-screen-up) {
        order: 1;
      }
    }

    &:nth-child(2) {
      order: 1;

      @include media($l-screen-up) {
        order: 2;
      }
    }

    &:nth-child(3) {
      order: 3;
    }

    ul {
      @include listReset;
    }

    li {
      margin-bottom: $gutter-small;
    }

    a {
      text-decoration: none;
    }
  }
}
