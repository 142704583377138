.hero {
  display: flex;
  position: relative;
  height: 0;
  padding-bottom: 110%;

  @include media($m-screen-up) {
    height: 90vh;
    padding-bottom: 0;
  }

  &__featured {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .glide,
    .glide__track,
    .glide__slides,
    .glide__slide {
      height: 100%;
    }
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: $black;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
    }

    &::before {
      top: 0;
      height: 20%;
      background: linear-gradient(to bottom, rgba($black, 0.7), transparent);
      z-index: 1;
    }

    &::after {
      bottom: 0;
      height: 30%;
      background: linear-gradient(to top, rgba($black, 0.7), transparent);
    }

    &--image {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &--video {
      width: 100%;
      height: 100%;
      overflow: hidden;

      iframe {
        width: 177.77777778vh;
        height: 56.25vw;
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__summary {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
  }

  &__text {
    color: $white;
    padding: $gutter;
    margin-bottom: $gutter;

    @include media($m-screen-up) {
      padding: $gutter-large;
      margin-bottom: 0;
      width: 80%;
    }

    h1,
    a {
      transition: all 0.4s $easing-quintic;

      .slider-is-moving & {
        opacity: 0;
        transform: translate3d(rem(-30), rem(30), 0);
      }
    }

    h1 {
      font-size: rem(22);
      transition-delay: 0.1s;

      @include media($m-screen-up) {
        font-size: rem(26);
        margin-bottom: $gutter-small;
      }
    }

    a {
      font-weight: normal;
      position: relative;
      font-size: rem(14);

      @include media($m-screen-up) {
        font-size: rem(18);
      }
    }
  }
}
